import React from 'react'

export default function Error() {
  return (
    <div>
      Error
      <h5>error</h5>
    </div>
  )
}
